export default {
  NAMESPACE: 'Wix_Ads',
  MAIN_TEXT_KEY: 'Wix_Ads_2_top_banner',
  MAIN_TEXT_DEFAULT:
    'This site was designed with the {Wix} website builder. Create your website today.',
  BUTTON_TEXT_KEY: 'Wix_Ads_2_top_banner_CTA',
  BUTTON_TEXT_DEFAULT: 'Start Now',
  MAIN_TEXT_KEY_NEW: 'Wix_Ads_2_top_banner_new',
  MAIN_TEXT_DEFAULT_NEW:
    '{Wix} This website was built on Wix. Create yours today.',
  BUTTON_TEXT_KEY_NEW: 'Wix_Ads_2_top_banner_CTA_new',
  BUTTON_TEXT_DEFAULT_NEW: 'Get Started',
  HREF_KEY_OLD: 'Wix_Ads_2_top_banner_href_old',
  HREF_KEY_DEFAULT: '//www.wix.com/lpviral/enviral?utm_campaign=vir_wixad_live',
  HREF_KEY_NEW: 'Wix_Ads_2_top_banner_href_new',
  wixLogoPlaceHolder: '{Wix}',
};
